<template>
  <div>
    <div class="tv">
      <img class="tvImg" src="../../assets/tv/maxhub.png" alt="" width="100%" />
    </div>
    <div class="tv-introduce">
      <div class="title">MAXHUB</div>
      <p class="content" v-html="getHtml('HighQuality')"></p>
    </div>
    <div class="other">
      <div class="container">
        <h3 class="title">FEATURE HIGHLIGHT</h3>
        <div class="tabs">
          <div class="item">{{ this.$t("MAXHUB.MmUltraThin") }}</div>
          |
          <div class="item">{{ this.$t("MAXHUB.PixelHDR") }}</div>
          |
          <div class="item">{{ this.$t("MAXHUB.ANewSeam") }}</div>
        </div>
        <div class="content">
          <div class="card">
            <img src="../../assets/tv/card1.jpg" alt="" />
            <h3 class="message">{{ this.$t("MAXHUB.boxA.title") }}</h3>
            <p class="content">
              {{ this.$t("MAXHUB.boxA.content") }}
            </p>
          </div>
          <div class="card">
            <img src="../../assets/tv/card2.jpg" alt="" />
            <h3 class="message">{{ this.$t("MAXHUB.boxB.title") }}</h3>
            <p class="content">{{ this.$t("MAXHUB.boxB.content") }}</p>
          </div>
          <div class="card">
            <img src="../../assets/tv/card3.jpg" alt="" />
            <h3 class="message">{{ this.$t("MAXHUB.boxC.title") }}</h3>
            <p class="content">
              {{ this.$t("MAXHUB.boxC.content") }}
            </p>
          </div>
          <div class="card">
            <img src="../../assets/tv/card4.jpg" alt="" />
            <h3 class="message" v-html="getHtml('boxD.title')"></h3>
            <p class="content" v-html="getHtml('boxD.content')"></p>
          </div>
          <div class="card">
            <img src="../../assets/tv/card5.jpg" alt="" />
            <h3 class="message">{{ this.$t("MAXHUB.boxE.title") }}</h3>
            <p class="content">
              {{ this.$t("MAXHUB.boxE.content") }}
            </p>
          </div>
          <div class="card">
            <img src="../../assets/tv/card6.jpg" alt="" />
            <h3 class="message">{{ this.$t("MAXHUB.boxF.title") }}</h3>
            <p class="content">
              {{ this.$t("MAXHUB.boxF.content") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce">
      <div class="detailed detailed1">
        <div class="left container">
          <div class="content">
            <h3>4K</h3>
            <h3>Ultra Thin Display</h3>
            <p v-html="getHtml('TheScreen')"></p>
          </div>
        </div>
      </div>
      <div class="detailed detailed2">
        <div class="right container">
          <div class="content">
            <h3>HDR</h3>
            <h3>Flip-Over Camera</h3>
            <p v-html="getHtml('ForPrivacy')"></p>
          </div>
        </div>
      </div>
      <div class="detailed detailed3">
        <div class="left container">
          <div class="content">
            <h3></h3>
            <h3>Seam Speakers</h3>
            <p v-html="getHtml('HighPower')"></p>
          </div>
        </div>
      </div>
      <div class="detailed detailed4">
        <div class="right container">
          <div class="content">
            <h3></h3>
            <h3>Voice Localisation</h3>
            <p v-html="getHtml('EquippedWith')"></p>
          </div>
        </div>
      </div>
      <div class="detailed detailed5">
        <div class="left container">
          <div class="content">
            <h3></h3>
            <h3>Multi Mirroring Methods</h3>
            <p v-html="getHtml('FeaturesAnd')"></p>
          </div>
        </div>
      </div>
      <div class="detailed detailed6">
        <div class="right container">
          <div class="content">
            <h3></h3>
            <h3>Windows 10</h3>
            <p v-html="getHtml('PlugableDesign')"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="imageSet container">
      <h3 class="title">{{ this.$t("MAXHUB.ImageSet") }}</h3>
      <div class="content">
        <div class="card">
          <img src="../../assets/tv/img_install_01.jpg" alt="" />
          <div class="message">
            <h4>{{ this.$t("MAXHUB.ConferenceRoomMiddle") }}</h4>
            <p>
              {{ this.$t("MAXHUB.TheConference") }}
            </p>
          </div>
          <div class="message">
            <h4>{{ this.$t("MAXHUB.ConferenceRoomMiddle") }}</h4>
            <p>65inch / 86inch</p>
          </div>
        </div>
        <div class="card">
          <img src="../../assets/tv/img_install_02.png" alt="" />
          <div class="message">
            <h4>{{ this.$t("MAXHUB.ConferenceRoomHigh") }}</h4>
            <p v-html="getHtml('InTheConference')"></p>
          </div>
          <div class="message">
            <h4>{{ this.$t("MAXHUB.RecommendedSize") }}</h4>
            <p>86inch</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    getHtml(text) {
      return this.$t(`MAXHUB.${text}`);
    },
  },
};
</script>
    
<style scoped lang='less'>
.tvImg {
  vertical-align: bottom;
}
.tv-introduce {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 534px;
  background: url("../../assets/tv/banner.png") no-repeat center;
  background-size: cover;
  text-align: center;
  font-size: 18px;
  font-family: "Meiryooo";
  color: #ffffff;
  .title {
    margin-bottom: 50px;
    font-size: 50px;
    font-weight: 700;
  }
  .content{
    font-weight: 500;
  }

}
.other {
  padding-bottom: 120px;
  background-color: #f2f5f8;
  .title {
    padding: 80px 0 66px 0;
    text-align: center;
    font-size: 40px;
    font-family: "Meiryooo";
    color: #000000;
  }
  .tabs {
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
    height: 64px;
    font-weight: 700;
    font-family: "Meiryooo";
    line-height: 64px;
    border-radius: 5px;
    background-color: #fff;
    .item {
      width: 33%;
      text-align: center;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card {
      width: 374px;
      height: 374px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: flex-start;
      font-size: 14px;
      font-family: "Meiryooo";
      font-weight: 700;
      color: #000000;
      img {
        height: 204px;
      }
      .content {
        text-align: left;
      }
      .content,
      .message {
        width: 350px;
      }
      .message {
        padding: 20px 0;
        font-size: 20px;
      }
    }
  }
}
.introduce {
  color: #ffffff;
  .container {
    display: flex;
    align-items: center;
    height: 700px;
    h3 {
      font-size: 30px;
    }
    p {
      margin-top: 40px;
      display: inline-block;
      width: 400px;
      font-size: 16px;
    }
  }
  .left {
    display: flex;
    justify-content: flex-start;
  }
  .right {
    color: #000000;
    display: flex;
    justify-content: flex-end;
  }
  .detailed {
    background: no-repeat center;
    background-size: cover;
    font-family: 'Meiryooo';
  }
  .detailed1 {
    background-image: url("~@/assets/tv/4k.jpg");
  }
  .detailed2 {
    background-image: url("~@/assets/tv/HDR.jpg");
  }
  .detailed3 {
    background-image: url("~@/assets/tv/Seam Speakers.jpg");
  }
  .detailed4 {
    background-image: url("~@/assets/tv/Voice Localisation.jpg");
  }
  .detailed5 {
    background-image: url("~@/assets/tv/Multi Mirroring Methods.jpg");
  }
  .detailed6 {
    background-image: url("~@/assets/tv/Windows 10.jpg");
  }
}
.imageSet {
  font-size: 16px;
  font-family: 'Meiryooo';
  .title {
    margin: 100px 0 60px 0;
    font-size: 26px;
    font-weight: 700;
    color: #000;
  }
  .content {
    display: flex;
    justify-content: space-between;
    h3 {
      font-family: "meiryooo";
    }
    img {
      width: 480px;
      height: 447px;
    }
    .message {
      width: 180px;
    }
    h4 {
      margin: 20px 0;
      font-size: 20px;
      font-weight: 700;
    }
    p {
      width: 389px;
      height: 118px;
    }
  }
}
</style>
    