<template>
  <div>
    <div class="header">
      <img width="100%" src="../../assets/mobileTv/header.png" alt="" />
    </div>
    <div class="banner">
      <p>MAXHUB</p>
      <p v-html="getHtml(`MAXHUB.HighQuality`)"></p>
    </div>
    <div class="content">
      <p class="title">FEATURE HIGHLIGHT</p>
      <ul class="func">
        <li>
          <img width="100%" src="../../assets/mobileTv/details1.png" alt="" />
          <p>{{ this.$t("MAXHUB.boxA.title") }}</p>
          <p>{{ this.$t("MAXHUB.boxA.content") }}</p>
        </li>
        <li>
          <img width="100%" src="../../assets/mobileTv/details2.png" alt="" />
          <p>{{ this.$t("MAXHUB.boxB.title") }}</p>
          <p>{{ this.$t("MAXHUB.boxB.content") }}</p>
        </li>
        <li>
          <img width="100%" src="../../assets/mobileTv/details3.png" alt="" />
          <p>{{ this.$t("MAXHUB.boxC.title") }}</p>
          <p>{{ this.$t("MAXHUB.boxC.content") }}</p>
        </li>
        <li>
          <img width="100%" src="../../assets/mobileTv/details4.png" alt="" />
          <p v-html="getHtml('MAXHUB.boxD.title')"></p>
          <p v-html="getHtml('MAXHUB.boxD.content')"></p>
        </li>
        <li>
          <img width="100%" src="../../assets/mobileTv/details5.png" alt="" />
          <p>{{ this.$t("MAXHUB.boxE.title") }}</p>
          <p>{{ this.$t("MAXHUB.boxE.content") }}</p>
        </li>
        <li>
          <img width="100%" src="../../assets/mobileTv/details6.png" alt="" />
          <p>{{ this.$t("MAXHUB.boxF.title") }}</p>
          <p>{{ this.$t("MAXHUB.boxF.content") }}</p>
        </li>
      </ul>
      <ul class="product">
        <li>
          <div class="box">
            <p class="box-title">4K Ultra Thin Display</p>
            <p v-html="getHtml('MAXHUB.TheScreen')" class="introduction"></p>
          </div>
        </li>
        <li>
          <div class="box">
            <p class="box-title">HDR Flip-Over Camera</p>
            <p v-html="getHtml('MAXHUB.ForPrivacy')" class="introduction"></p>
          </div>
        </li>
        <li>
          <div class="box">
            <p class="box-title">Seam Speakers</p>
            <p v-html="getHtml('MAXHUB.HighPower')" class="introduction"></p>
          </div>
        </li>
        <li>
          <div class="box">
            <p class="box-title">Voice Localisation</p>
            <p v-html="getHtml('MAXHUB.EquippedWith')" class="introduction"></p>
          </div>
        </li>
        <li>
          <div class="box">
            <p class="box-title">Multi Mirroring Methods</p>
            <p v-html="getHtml('MAXHUB.FeaturesAnd')" class="introduction"></p>
          </div>
        </li>
        <li>
          <div class="box">
            <p class="box-title">Windows 10</p>
            <p
              v-html="getHtml('MAXHUB.PlugableDesign')"
              class="introduction"
            ></p>
          </div>
        </li>
      </ul>
    </div>
    <div class="settings">
      <h1>{{ this.$t("MAXHUB.ImageSet") }}</h1>
      <ul class="settings-box">
        <li>
          <img width="100%" src="../../assets/mobileTv/content.png" alt="" />
          <h2>{{ this.$t("MAXHUB.ConferenceRoomMiddle") }}</h2>
          <p>{{ this.$t("MAXHUB.TheConference") }}</p>
          <h2>{{ this.$t("MAXHUB.RecommendedSize") }}</h2>
          <span>65inch / 86inch</span>
        </li>
        <li>
          <img width="100%" src="../../assets/mobileTv/img_install_01@2x.png" alt="" />
          <h2>{{ this.$t("MAXHUB.ConferenceRoomHigh") }}</h2>
          <p v-html="getHtml('MAXHUB.InTheConference')"></p>
          <h2>{{ this.$t("MAXHUB.RecommendedSize") }}</h2>
          <span>86inch</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    getHtml(text) {
      return this.$t(text);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  img {
    vertical-align: bottom;
  }
}
.banner {
  // background-color: #000;
  background: url('../../assets/mobileTv/banner.png') no-repeat center;
  background-size:cover;
  height: 56vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: #fff;
  }
  p:first-child {
    font-size: 3.7333vw;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 5.0667vw;
    margin: 8vw 0 5.3333vw 0;
  }
  p:last-child {
    font-size: 3.2vw;
    font-family: "Meiryooo";
    font-weight: 400;
    line-height: 5.3333vw;
    text-align: center;
  }
}
.content {
  .title {
    font-size: 16px;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 21px;
    color: #333333;
    text-align: center;
    margin: 10.6667vw 0 8vw 0;
  }
  .func {
    li {
      margin-bottom: 13.3333vw;
      img {
        vertical-align: bottom;
      }
      p {
        text-align: center;
        font-family: "Meiryooo";
        color: #000000;
      }
      p:nth-child(2) {
        margin-top: 3.7333vw;
        font-size: 3.7333vw;
        font-weight: bold;
        line-height: 5.0667vw;
      }
      p:last-child {
        margin: 0 16px;
        font-size: 3.2vw;
        font-weight: 400;
        line-height: 4.2667vw;
        text-align: center;
      }
    }
    li:nth-child(4) {
      p:nth-child(2) {
        margin-bottom: 2.9333vw;
      }
    }
  }
  .product {
    li {
      width: 100vw;
      height: 149.3333vw;
      background-size: cover !important;
      overflow: hidden;
      .box {
        margin: 10.6667vw 4.2667vw 0;
        height: 50%;
        .box-title {
          font-size: 5.3333vw;
          font-family: "Meiryooo";
          font-weight: bold;
          line-height: 7.4667vw;
          color: #ffffff;
          margin-bottom: 5.3333vw;
        }
        .introduction {
          font-size: 3.2vw;
          font-family: "Meiryooo";
          font-weight: 400;
          line-height: 6.4vw;
          color: #cccccc;
          br{
            line-height: 3.2vw !important;
          }
        }
      }
    }
    li:first-child {
      background: url("../../assets/mobileTv/product1.png") no-repeat center;
    }
    li:nth-child(2) {
      background: url("../../assets/mobileTv/product2.png") no-repeat center;
      p {
        color: #000 !important;
      }
    }
    li:nth-child(3) {
      background: url("../../assets/mobileTv/product3.png") no-repeat center;
    }
    li:nth-child(4) {
      background: url("../../assets/mobileTv/product4.png") no-repeat center;
      p {
        color: #000 !important;
      }
    }
    li:nth-child(5) {
      background: url("../../assets/mobileTv/product5.png") no-repeat center;
    }
    li:last-child {
      background: url("../../assets/mobileTv/product6.png") no-repeat center;
      p {
        color: #000 !important;
      }
    }
  }
}
.settings {
  padding: 0 4.2667vw;
  overflow: hidden;
  h1 {
    margin: 12vw 0 5.8667vw 0;
    font-size: 4.2667vw;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 5.8667vw;
    color: #000000;
  }
  .settings-box {
    li {
      margin-bottom: 12vw;
      h2 {
        font-size: 4.2667vw;
        font-family: "Meiryooo";
        font-weight: bold;
        line-height: 5.8667vw;
        color: #000000;
        margin-bottom: 5.3333vw;
      }
      p,
      span {
        font-size: 3.7333vw;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 6.4vw;
        color: #000000;
      }
      p {
        margin-bottom: 8vw;
      }
    }
  }
}
</style>